import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ButtonGrid from '../ButtonGrid';
import React from 'react';
import '../../fonts/fonts.css';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerContainer: {
      margin: 20,
      marginTop: 30,
    },
    callToActionText: {
      marginBottom: 30,
      marginTop: 20,
      fontWeight: 200,
    },
  })
);

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <Typography variant="h6" className={classes.callToActionText}>
        <i>Listen to Zynth Punk... at your favorite Streaming Service</i>
      </Typography>
      <ButtonGrid />
    </div>
  );
}

import { Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGridContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1),
    },
    buttonGridItem: {
      display: 'flex',
      alignContent: 'center',
    },
    buttonLinkObject: {
      fontSize: 20,
      color: theme.palette.common.white,
      textAlign: 'center',
      textDecoration: 'none',
      fontFamily: 'Roboto Mono',
      fontWeight: 200,
      '&:hover': {
        color: theme.palette.primary.light,
        fontWeight: 400,
      },
    },
    iconStyling: {
      color: theme.palette.common.white,
      fontSize: 30,
    },
  })
);

export default function ButtonGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.buttonGridContainer} spacing={3}>
        <Grid item xs={12} sm={6} md={3} className={classes.buttonGridItem}>
          <a
            href="https://open.spotify.com/artist/6BdSmIpqVam6MashlNUOoK?si=Ce1B6Bt0RKCuRjGNMvXjbA"
            className={classes.buttonLinkObject}
          >
            {`< Spotify >`}
          </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.buttonGridItem}>
          <a
            href="https://music.apple.com/ca/artist/zynth-punk/1527772960"
            className={classes.buttonLinkObject}
          >
            {`< Apple Music >`}
          </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.buttonGridItem}>
          <a
            href="https://music.youtube.com/channel/UCImnlKIYMvd4mh9heh3CrIg?feature=share"
            className={classes.buttonLinkObject}
          >
            {`< Youtube Music >`}
          </a>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.buttonGridItem}>
          <a
            href="https://music.amazon.com/artists/B09MHC1QDZ/zynth-punk"
            className={classes.buttonLinkObject}
          >
            {`< Amazon Music >`}
          </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.buttonGridItem}>
          <a
            href="https://www.youtube.com/channel/UC8g4Ktd0WRcT7-k2KCTxBWA/videos?view=0&sort=p&flow=grid"
            className={classes.buttonLinkObject}
          >
            {`< Youtube Channel >`}
          </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.buttonGridItem}>
          <a
            href="https://soundcloud.com/zynthpunk"
            className={classes.buttonLinkObject}
          >
            {`< SoundCloud >`}
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

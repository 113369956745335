import { Grid, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import React from 'react';
import '../../fonts/fonts.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 20,
      gap: 20,
    },
    headerLinkComponent: {
      color: theme.palette.common.white,
      fontFamily: 'Roboto Mono',
      fontSize: 40,
      fontWeight: 200,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.light,
        fontWeight: 400,
      },
    },
    headerTitleTypography: {
      fontFamily: 'Roboto Mono',
    },
    pageItem: {
      alignSelf: 'left',
    },
    pageLinkComponent: {
      color: theme.palette.common.white,
      fontFamily: 'Roboto Mono',
      textDecoration: 'none',
      fontSize: 20,
      fontWeight: 200,
      '&:hover': {
        color: theme.palette.primary.light,
        fontWeight: 400,
      },
    },
    pageTypography: {
      fontSize: 20,
    },
  })
);

export default function Header({ title }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container className={classes.headerContainer}>
        <Grid item className={classes.pageItem}>
          <Typography variant="h4" className={classes.headerTitleTypography}>
            <Link className={classes.headerLinkComponent} to="/">
              {title}
            </Link>
          </Typography>
        </Grid>
        <Grid item className={classes.pageItem}>
          <Typography variant="h6" className={classes.pageTypography}>
            <Link className={classes.pageLinkComponent} to="/releases">
              {`< releases >`}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
